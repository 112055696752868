import React from "react";
import { ContactInfo, ContactForm, Layout } from "../components";
import styled from "styled-components";
import Helmet from "react-helmet";

export default ({ data }) => {
    const {
        allMarkdownRemark: { edges },
    } = data;
    const { cell_phone, email, fax_number, job_title, office_phone, form_title } = edges[0].node.frontmatter;
    return (
        <>
            <Helmet>
                <title>Contact - Braden Chamberlain - Security Home Mortgage</title>
                <meta
                    name="description"
                    content="I'm Braden Chamberlain; a loan officer specializing in Utah home loans and refinancing."
                />
                <meta property="og:image:secure_url" content="https://mortgagesbybraden.netlify.app/ogpImage.jpg" />
                <meta property="og:image" content="http://mortgagesbybraden.netlify.app/ogpImage.jpg" />
                <meta property="og:title" content="Contact - Braden Chamberlain - Security Home Mortgage" />
                <meta
                    property="og:description"
                    content="I'm Braden Chamberlain; a loan officer specializing in Utah home loans and refinancing."
                />
                <meta property="og:url" content="https://mortgagesbybraden.netlify.app/" />
            </Helmet>
            <Layout>
                <StyledSection id="contact">
                    <h2>Contact Braden</h2>
                    <Wrapper>
                        <ContactInfo data={{ cell_phone, email, fax_number, job_title, office_phone }} />
                        <ContactForm data={{ form_title }} />
                    </Wrapper>
                </StyledSection>
            </Layout>
        </>
    );
};

export const query = graphql`
    query ContactInfo {
        allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/contact/" } }) {
            edges {
                node {
                    frontmatter {
                        cell_phone
                        email
                        fax_number
                        job_title
                        office_phone
                        form_title
                    }
                }
            }
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem;
    max-width: 1500px;
    width: 100%;
`;

const StyledSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    padding: 0 1rem;
    h2 {
        text-align: center;
        font-size: 4rem;
    }

    .button {
        background: ${({ theme }) => theme.colorSecondary};
        margin: 3rem 0;
        width: 100%;
        padding: 1rem;
        width: 200px;
        border-radius: 5px;
        color: ${({ theme }) => theme.textPrimary};
        text-decoration: none;
        font-size: 1.8rem;
        text-align: center;
        box-sizing: border-box;

        border: 3px solid ${({ theme }) => theme.colorPrimary};
        border-radius: 20px;

        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }
`;
